export default (theme) => ({
	root: {
		flexGrow: 1,
	},
	AppBar: {
		color: theme.palette.secondary.main,
		backgroundColor: "transparent",
		boxShadow: "none",
	},
	Toolbar: {
		paddingLeft: 16,
		paddingRight: 16,
		justifyContent: "space-between",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
		},
		[theme.breakpoints.up("sm")]: {
			minHeight: 72,
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: 64,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		margin: 0,
	},
	list: {
		width: 250,
	},
	listIcon: {
		minWidth: 40,
	},
	listItem: {
		fontWeight: 600,
		fontSize: 16,
		lineHeight: 1.5,
	},
	profile: {
		"borderRadius": 8,
		"&:hover": {
			background: "rgba(34, 34, 34, 0.04)",
		},
	},
	adminListItemText: {
		fontWeight: 600,
	},
	adminListItemIcon: {
		minWidth: 40,
		color: theme.palette.secondary.main,
	},
	popperMenu: {
		width: 240,
	},
	inboxBadge: {
		position: "relative",
	},
	inboxBadgeAnchor: {
		top: 10,
		right: 4,
	},
	listBadge: {
		right: 12,
	},
	buttonMargin: {
		marginRight: 12,
	},
});
