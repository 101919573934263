import { action, computed, observable, toJS } from "mobx";
import rootService from "../@data/service";

const { getAccountByToken, countMessages } = rootService;

class HeaderHomeStore {
	noPicName = "no_pic.png";
	filter = {
		from_provider: true,
		read: false,
	};

	constructor(rootStore, staticContext) {
		this.rootStore = rootStore;
	}

	@observable _user = null;
	@computed
	get user() {
		return toJS(this._user);
	}

	@computed
	get client() {
		return toJS(!!this.user && !!this.user.client ? this.user.client : null);
	}

	@computed
	get provider() {
		return toJS(
			!!this.user && !!this.user.provider ? this.user.provider : null
		);
	}

	@computed
	get image() {
		if (!!this.provider && !!this.provider.image) {
			const splitSrc = this.provider.image.value.split("/");
			return splitSrc[splitSrc.length - 1] === this.noPicName
				? null
				: this.provider.image.value;
		}

		if (!!this.client && !!this.client.image) {
			const splitSrc = this.client.image.value.split("/");
			return splitSrc[splitSrc.length - 1] === this.noPicName
				? null
				: this.client.image.value;
		}

		return null;
	}

	@observable _inboxCount = 0;
	@computed
	get inboxCount() {
		return toJS(this._inboxCount);
	}

	@action
	async init(type) {
		try {
			this._user = await getAccountByToken({});
			if (!this._user) return;
			if (this.isClientType(type)) {
				this._inboxCount = await countMessages({
					filter: {
						client: this.user.client._id,
						...this.filter,
					},
				});
			}
		} catch (err) {
			throw err;
		}
	}

	isProviderType(type) {
		return type === "PROVIDER" || type === "USER";
	}

	isAdminType(type) {
		return type === "ADMIN" || type === "SUPERADMIN";
	}

	isClientType(type) {
		return type === "CLIENT";
	}

	@action
	redirectToAccount(type) {
		switch (type) {
			case "CLIENT":
				return "/client/account";
			case "USER":
				return "/pros";
			case "PROVIDER":
				return "/pros";
			case "ADMIN":
				return "/admin";
			case "SUPERADMIN":
				return "/admin";
			default:
				return "/";
		}
	}
}

export default HeaderHomeStore;
