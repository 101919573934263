import gql from "graphql-tag";

export const clientByTokenQueryFragment = gql`
	fragment clientByTokenQueryFragment on Client {
		_id
		image {
			value
			hash
			original
		}
	}
`;

export const providerByTokenQueryFragment = gql`
	fragment providerByTokenQueryFragment on Provider {
		_id
		image {
			value
			hash
			original
		}
	}
`;

export const accountByTokenQueryFragment = gql`
	fragment accountByTokenQueryFragment on Account {
		_id
		client {
			_id
			first_name
			last_name
			image {
				value
				hash
				original
			}
		}
		provider {
			_id
			image {
				value
				hash
				original
			}
		}
	}
`;

export const countMessagesQueryFragment = true;
